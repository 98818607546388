import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import { graphql } from "gatsby"
import React from "react"
import Navbar from "../components/Utilities/Navbar/Navbar"
import Img from "gatsby-image"
import "../styles/serviceItem.css"
import { Button } from "@chakra-ui/button"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import FooterCommon from "../components/Utilities/FooterCommon/FooterCommon"
import logo from "../images/sensetechLogo.png"
import { Helmet } from "react-helmet"

const services = ({ data }) => {
  const serviceArr = data.allMarkdownRemark.nodes

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SenseTech: Services</title>
        <meta
          name="description"
          content="SenseTech : App Development || Cloud Support || Migration to Micro Frontend || Cloud Migration support || RPA || Chatbot || E-commerce store"
        />
        <meta
          name="keywords"
          content="mobile app development , web app development , software company , IT solution, IT services"
        />
        <meta name="author" content="SenseTech Innovations Private Limited" />
        <meta property="og:image" content={logo} />
        <link rel="canonical" href="https://thesensetech.com" />
      </Helmet>
      <Box minHeight="110vh">
        <Navbar />
        <Text
          mb="10px"
          mt="130px"
          textAlign="center"
          fontSize="40px"
          fontWeight="700"
        >
          Our Services
        </Text>

        <Box maxW="1140px" margin="0 auto">
          {serviceArr.map(item => (
            <ServiceDetail item={item} key={item.id} />
          ))}
        </Box>
        <FooterCommon />
      </Box>
    </>
  )
}

export default services

const ServiceDetail = ({ item }) => {
  console.log(item)
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      borderBottom="1px solid #ccc"
      id={item.frontmatter.hashId}
      className="serviceDetail"
    >
      <Box>
        <Img
          className="serviceDetail__img"
          fluid={item.frontmatter.thumb.childrenImageSharp[0].fluid}
        />
      </Box>
      <Box>
        <Text
          mb="32px"
          fontSize={["28px", "30px", "32px", "32px"]}
          fontWeight="500"
        >
          {item.frontmatter.title}
        </Text>
        <Box
          className="serviceInner__html"
          fontSize="18px"
          dangerouslySetInnerHTML={{ __html: item.html }}
        ></Box>
        <AnchorLink to="/services">
          <Button
            mt="20px"
            bg="white"
            color="#000"
            border="1px solid #000"
            p="10px 20px"
            borderRadius="7px"
            colorScheme="#02073e"
            className="backToTop"
          >
            Back to Top
          </Button>
        </AnchorLink>
      </Box>
    </Box>
  )
}

export const query = graphql`
  query ServiceData {
    allMarkdownRemark(
      filter: { frontmatter: { group: { eq: "service" } } }
      sort: { order: ASC, fields: frontmatter___number }
    ) {
      nodes {
        frontmatter {
          hashId
          slug
          title
          thumb {
            childrenImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  }
`
